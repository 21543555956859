import { Input } from 'reactstrap';
import './ResourceSearchInput.scss';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimatedPulse from '../Loading/AnimatedPulse/AnimatedPulse';
import config from '@config/index';
import useResourceSearchInput from '@hooks/useResourceSearchInput';


interface ResourceSearchInputProps {
  isAuth?: boolean;
}

function ResourceSearchInput({ isAuth }: ResourceSearchInputProps) {
  const { search, result, searchLoading, searchFetching } = useResourceSearchInput();
  const [inputValue, setInputValue] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isClickedOpen, setIsClickedOpen] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef<any>(null);

  const handleInputChange = (e: any) => {
    const query = e.target.value;
    setInputValue(query);
    search(query);
    if(!isDropdownVisible) setDropdownVisible(true);
    setDropdownVisible(!!query);  // Show dropdown if there’s a query
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      navigate(`/search?keyword=${inputValue}`)
    }
  };

  const handleSuggestionClick = (id: any, subsite_code: string) => {
    // You might want to trigger some action on suggestion click, like updating the search field
    setDropdownVisible(false);
    switch(subsite_code) {
      case 'tr': {
        navigate(`dashboard/tr/ultimatepage/page?id=${id}&selected=0`);
        return;
      }
      case 'dvr': {
        location.href = (`${config.subsitesUrl.dvr}/resource/${id}` );
        return;
      }
    }
  };

  const handleOnFocus = () => {
    setDropdownVisible(!!result.length);
    setIsClickedOpen(true);
  }

  const handleBlur = () => {
    setTimeout(() => setDropdownVisible(false), 200);  // Delay to allow suggestion click
    setIsClickedOpen(false);
  };

  const getSubsiteName = (subsite_code: string) => {
    switch(subsite_code) {
      case 'tr': return 'Teaching Resources';
      case 'dvr': return 'Digital Resources';
    }
  }
  
  const handleWrapperClicked = () => {
    if(searchRef && searchRef.current) {
      searchRef.current.focus();
    }
    setIsClickedOpen(true)
  }

  return (
    <div onClick={handleWrapperClicked} className={`alp-resource-search-input ${isClickedOpen ? 'open' : ''} ${!isAuth ? 'unauthenticated' : ''}`}>
      <i className="icon icon-search" />
      <Input
        innerRef={searchRef}
        className={ `${(isDropdownVisible && 'dropdown-showing')}` }
        placeholder="Search"
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}  // Hide dropdown on input blur
      />
      { (searchLoading || searchFetching) && <AnimatedPulse color='green' /> }
      {isDropdownVisible && result && result.length > 0 && (
        <ul className="suggestions-dropdown">
          {result.map((item) => (
            <li
              key={item.id}
              onClick={() => handleSuggestionClick(item.id, item.subsite_code)}
              className="suggestion-item"
            >
              <i className="fa-duotone fa-solid fa-magnifying-glass"></i>
              <span>{item.name}</span>
                <span className={`subsite-${item.subsite_code}`}>
                  { getSubsiteName(item.subsite_code) }
                </span>
            </li>
          ))}
          <li className='suggestion-result'>Search Result {result.length}</li>
        </ul>
      )}
    </div>
  );
}

export default ResourceSearchInput;
